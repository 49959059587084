'use strict';

const glio = require('gliojs');
const WOW = require('wowjs').WOW;

const App = {
   init: function () {
	  require('./scripts');
	  console.log('Initialised website');
  }
};

module.exports = App;