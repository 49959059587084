'use strict';

// Init Wow scrolling animations
var img = new Image();
img.src = 'images/micro-inr-hand.png';

img.onload = function () {
  new WOW({
    offset: 200
  }).init();
}

// Show modal on exit intent
glio.init(
  ['top', function () {
    $('#leadCapture').modal('show');
  }]
);

/*
 * Small function which posts to Slack
 */
function postMessageToSlack(webhookUrl, payload){
  var xmlhttp = new XMLHttpRequest();
  xmlhttp.open('POST', webhookUrl, false);
  xmlhttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
  xmlhttp.send(JSON.stringify(payload));
}

/*
 * Validate and submit lead form
 */
var form = document.getElementById('leadCaptureForm');
form.addEventListener('submit', function (event) {

  event.preventDefault();
  event.stopPropagation();

  form.classList.add('was-validated');

  // Submit the form if valid
  if (form.checkValidity() !== false) {
    // Because the form submission is prevented we can log a successful submission to Google tag manger using this code
    // As detailed here https://www.simoahava.com/analytics/track-form-engagement-with-google-tag-manager/#2
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'leadFormSubmit'
    });

    // Post a notification to Slack
    var practiceName = document.getElementById('companyname').value;
    var fullName = document.getElementById('firstname').value + ' ' + document.getElementById('lastname').value;
    if (!practiceName) practiceName = 'Home User';
    var payload = {
      "username": "microINR Bot",
      "icon_url": "https://www.microinr.com.au/images/microinr-icon.png",
      "attachments": [
        {
          "title": practiceName,
          "pretext": "A microINR lead was just captured through the landing page!",
          "text": fullName + " (<https://system.na2.netsuite.com/app/common/search/searchresults.nl?searchid=582&whence=|View in Netsuite>)"
        }
      ]
    };
    postMessageToSlack('https://hooks.slack.com/services/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', payload);

    var $form = $(event.target);

    // Fluidic Netsuite hack to submit form data via query string
    var url = 'https://forms.netsuite.com/app/site/crm/externalleadpage.nl?compid=3923593&formid=21&h=AACffht_qw84WBP224lttzVxN1nUmjqnAdk%3D&';
    var queryString = $form.serialize();

    $form.siblings('.iframe').attr('src', url + queryString);
    $form.siblings('.iframe').css('height', $(this).height());
    $form.siblings('.iframe').css('width', $(this).width());
    $form.fadeOut(function () {
      $form.siblings('.iframe').fadeIn();
    });
  }
}, false);

/*
 * Switch module
 */

// Parse the URL parameter
function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

// Define parameters
var dynamicContent;
var dynamicKey;

// Replace content dynamically using query string
var switchContent = document.getElementsByClassName("switch-content");
for (var i = 0; i < switchContent.length; i++) {

  dynamicKey = switchContent[i].getAttribute('data-switch-key');

  if (dynamicKey) dynamicContent = getParameterByName(dynamicKey);

  if (dynamicContent) switchContent[i].textContent = dynamicContent;
}

// Define default audience
var defaultAudience = 'professional';

// Update audience from query sting parameter
var audience = getParameterByName('audience');

if (audience === 'professional') document.getElementById('comments').value = 'Viewed the professional version of the landing page';
if (audience === 'consumer') document.getElementById('comments').value = 'Viewed the consumer version of the landing page';

// Set default audience if necessary
if (!audience) audience = defaultAudience;

// Replace with content from data-audience-{value} in html code when query string audience={value}
var switchAudience = document.getElementsByClassName("switch-audience");
for (var i = 0; i < switchAudience.length; i++) {
  if (audience !== defaultAudience) switchAudience[i].textContent = switchAudience[i].getAttribute('data-audience-' + audience);
}

// Show elements based on audience from url parameter
var switchShow = document.getElementsByClassName('switch-show');
for (var i = 0; i < switchShow.length; i++) {
  switchShow[i].style.display = 'none';
  if (switchShow[i].getAttribute('data-switch-audience') === audience) switchShow[i].style.display = 'block';
}

// Hide elements based on audience from url parameter
var switchHide = document.getElementsByClassName('switch-hide');
for (var i = 0; i < switchHide.length; i++) {
  switchHide[i].style.display = 'none'; // Hidden by default to avoid flicker
  if (switchHide[i].getAttribute('data-switch-audience') !== audience) switchHide[i].style.display = 'block';
}

var urlParameter;
var formId;

// Populate form fields
var formInputs = document.getElementsByTagName('input');

for (var i = 0; i < formInputs.length; i++) {

  formId = formInputs[i].getAttribute('id');

  if (formId) urlParameter = getParameterByName(formId);

  if (urlParameter === 'Your Practice') return; // Don't fill when company name is 'Your Practice'

  if (urlParameter) document.getElementById(formId).value = urlParameter;

}